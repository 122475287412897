import {createRouter, createWebHistory} from 'vue-router/dist/vue-router';
import Login from '@/views/login/Login';
import Home from '@/views/home/Home';
import store from '@/store';
import Index from '@/views/web/Index';
import Article from "@/components/web/Article";
import Archive from "@/components/web/Archive";
import Category from "@/components/web/Category";
import Tag from "@/components/web/Tag";
import About from "@/components/web/About";
import Link from "@/components/web/Link";
import Editor from "@/views/web/Editor";
import Detail from "@/views/web/Detail";
import Post from '@/components/web/Post';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: Index,
            name: 'index',
            children: [
                {
                    path: '',
                    component: Article,
                    name: 'article'
                },
                {
                    path: 'archive',
                    component: Archive,
                    name: 'archive'
                },
                {
                    path: 'category',
                    component: Category,
                    name: 'category'
                },
                {
                    path: 'tag',
                    component: Tag,
                    name: 'tag'
                },
                {
                    path: 'about',
                    component: About,
                    name: 'about'
                },
                {
                    path: 'link',
                    component: Link,
                    name: 'link'
                }
            ]
        },
        {
            path: '/login',
            component: Login,
            name: "login"
        },
        {
            path: '/editor',
            component: Editor,
            name: "editor"
        },
        {
            path: '/post',
            component: Detail,
            name: 'detail',
            children: [
                {
                    path: ':id',
                    component: Post,
                    name: 'post'
                }
            ]
        },
        {
            path: '/home',
            component: Home,
            name: "home"
        }
    ]
})

// 路由守卫，当未登录时，非登录页面的任何页面都不允许跳转
router.beforeEach((to, from) => {
    let isLogin = store.getters.isLogin
    if (!isLogin && to.name === 'editor') {
        return {name: 'login'}
    } else {
        return true
    }
})

export default router;
