import request from '@/utils/request';

export default {
     login(data) {
        return request({
            url: '/base/login',
            method: 'post',
            data: data
        })
    },
}

