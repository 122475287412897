<template>
  <div>About</div>
</template>

<script>
export default {
  name: "About"
};
</script>

<style scoped>

</style>
