import { createApp } from 'vue'
import router from '@/router';
import store from '@/store';
import App from './App.vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconVue from '@element-plus/icons-vue'
import api from './api/index.js'
import '../public/style/iconfont/iconfont.css';

import {VueMarkdownEditor}  from './plugins/vmdeditor/index';

const app = createApp(App)

app.config.globalProperties.$http = api

app.use(router)
app.use(store)
for (const [key, component] of Object.entries(ElementPlusIconVue)) {
    app.component(key, component)
}
app.use(ElementPlus)
app.use(VueMarkdownEditor)
app.mount('#app')
