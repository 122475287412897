import {createStore} from 'vuex';

export default createStore({
    state () {
        return {
            token: localStorage.getItem("token") || '',
            userInfo: JSON.parse(sessionStorage.getItem("userInfo")) || {}
        }
    },
    getters: {
        isLogin: state => state.token.length > 0,
        getUser: state => state.userInfo
    },
    mutations: {
        // 设置 token
        SET_TOKEN: (state, token) => {
            state.token = token
            localStorage.setItem('token', token)
        },
        // 设置用户信息
        SET_USERINFO: (state, userInfo) => {
            state.userInfo = userInfo
            sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
        },
        // 清除缓存的用户信息，登出使用
        REMOVE_INFO: (state) => {
            state.token = ''
            state.userInfo = {}
            localStorage.removeItem("token")
            sessionStorage.removeItem("userInfo")
        }
    }
})
