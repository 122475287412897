<template>
  <div class="article-container">
    <div class="list-header">
      <span><a href="/">最新</a> </span>
      <span><a href="/">最热</a> </span>
    </div>
    <div class="list-body">
      <ul class="article-list-ul" v-for="article in articles">
        <li :key="article.articleId">
          <div class="article-cover">
            <div>
              <img :src="article.cover">
            </div>
          </div>
          <div class="article-body">
            <router-link :to="'/post/'+article.articleId" class="article-title" ><h2>{{article.title}}</h2></router-link>
            <p>
              ava 8 最大的变化是引入了函数式编
            </p>
            <div class="article-info">
              <span><i class="iconfont icon-shizhong"></i>2023-02-02 16:12:36</span>
              <span><i class="iconfont icon-fenlei">{{article.category.name}}</i></span>
              <span><i class="iconfont icon-yueduliang">{{article.viewCount}}</i></span>
              <span><i class="iconfont icon-dianzan">{{article.likeCount}}</i></span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      articles: [
        {
          cover: '',
          category: {}
        }
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0
    }
  },
  methods: {
    async getArticlePage() {
      const {data: res} = await this.$http.article.getArticlePage(this.currentPage, this.pageSize, null)
      console.log(typeof res);
      this.total = res.total
      this.articles = res.data
      console.log(this.articles);
    }
  },
  created() {
    this.getArticlePage()
  }
};
</script>

<style lang="scss" scoped>
.article-container {
  >.list-header {
    padding: 20px 20px 10px 20px;
    display: flex;
    justify-content: left;
    align-items: center;
    border-radius: 5px 5px 0 0;
    border-bottom: solid #d5d5d5 1px;
    margin-bottom: 5px;
  }
  span {
    margin-right: 20px;
  }
  a {
    color: #2b3539;
    text-decoration: none;
    &:hover {
      color: cornflowerblue;
    }
  }

  >.list-body {
    .article-list-ul {
      margin: 20px 0;
      padding: 0 10px;
      li {
        display: flex;
        border-bottom: solid #eaeaea 1px;
        height: 150px;
        .article-cover {
          width: 20%;
          padding: 20px 10px 20px 20px;
          //height: 160px;
          >div {
            width: 100%;
            border-radius: 5px;
            height: 100%;
            //height: 150px;
            overflow: hidden;
            img {
              width: 100%;
              //height: 100%;
              transition: all .3s;
              display: block;
            }
          }
        }
        .article-body {
          width: 80%;
          padding: 20px 10px 20px 20px;
          display: flex;
          flex-direction: column;
          align-items: baseline;
          justify-content: space-between;
          h2 {
            font-size: 26px;
            margin: 0;
            font-weight: 600;
          }
          p {
            margin: 0;
            //min-height: 50px;
            //max-height: 70px;
            height: 70px;
            overflow: hidden;
          }
          a {
            color: #2b3539;
          }
        }
      }
    }
  }
}
</style>
