<template>
  <div class="login-page">
    <div class="login-form">
      <h2>用户登录</h2>
      <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="rules"
          label-width="120px"
          class="demo-ruleForm"
          size="default"
          status-icon
      >
        <el-form-item label="用户名" prop="username">
          <el-input prefix-icon="User" placeholder="请输入用户名" v-model="loginForm.username" />
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input prefix-icon="Lock" autocomplete="new-password" show-password placeholder="请输入密码" v-model="loginForm.password" />
        </el-form-item>
        <el-form-item>
          <el-button class="login-btn" type="primary" @click="submitForm()">登录</el-button>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>

import {ElMessage} from "element-plus";
export default {
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { required: true, message: '请输入用户名', trigger: 'change' },
          { min: 5, max: 18, message: '长度应为5～18个字符', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { required: true, message: '请输入密码', trigger: 'change' },
          { min: 5, max: 18, message: '长度应为5～18个字符', trigger: 'blur' },
        ]
      },
      formSize: 'default'
    }
  },
  methods: {
    submitForm() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.handleLogin()
        } else {
          ElMessage.error('请确认后重新输入')
        }
      })
    },
    async handleLogin() {
      const {data: res} = await this.$http.user.login(this.loginForm)
      if (res.code !== 0) {
        ElMessage.error('登录失败')
        return
      }
      const data = res.data
      const token = data.token
      const userInfo = data.userInfo
      this.$store.commit('SET_TOKEN', token)
      this.$store.commit('SET_USERINFO', userInfo)
      await this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.login-page {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.login-form {
  width: 350px;
  background-color: rgba(234, 234, 234, 0.5);
  padding: 40px 60px;
  border-radius: 4px;
  text-align: center;
}

.login-btn {
  width: 100%;
}

el-input {
  padding: 0;
}

</style>
