import request from '@/utils/request';

export default {
  getArticlePage(page, pageSize, data) {
    return request({
      url: '/article/' + page + '/' + pageSize,
      method: 'post',
      data: data
    })
  },
  getArticleById(id) {
    return request({
      url: '/article/' + id,
      method: 'get'
    })
  }
}
