<template>
  <div>Category</div>
</template>

<script>
export default {
  name: "Category"
};
</script>

<style scoped>

</style>
