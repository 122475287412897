<template>
  <div class="base-view index-view">
    <Header></Header>
    <Main></Main>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/web/Header.vue";
import Main from "@/components/web/Main.vue";
import Footer from "@/components/web/Footer.vue";
export default {
  components: {
    Header,
    Main,
    Footer
  }
};
</script>

<style scoped>


.index-view {
  background-color: #f5f4f4;
  width: 100%;
}
</style>
