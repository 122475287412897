<template>
  <div class="main-container">
    <div class="content-container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style scoped>
.main-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 100px;
}
.content-container {
  background-color: white;
  min-height: 1000px;
  width: 60%;
}
</style>
