<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: rgb(239, 239, 239);
}

</style>
