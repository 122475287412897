<template>
  <div>
    Tag
  </div>
</template>

<script>
export default {
  name: "Tag"
};
</script>

<style scoped>

</style>
