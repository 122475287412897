<template>
  <div class="post-container">
    <div class="title-wrapper">
      <h1 class="article-title">{{article.title}}</h1>
      <div class="article-info">
        <span ><i class="iconfont icon-shizhong"></i>2023-02-02 16:12:36</span>
        <span ><i class="iconfont icon-yueduliang"></i>999 </span>
      </div>
    </div>
    <div class="line-before-content">
      <div></div>
    </div>
    <div class="content-container">
      <v-md-editor v-model="article.content" mode="preview"></v-md-editor>
    </div>
    <div class="line-after-content">
      <div></div>
    </div>
    <div class="comment-container">
      <div class="comment-form">
        <div class="header">
          <span class="header-title">评论</span>
        </div>
        <div class="content">
          <div class="avatar-box">
            <div class="avatar">
              <img src="http://blog-img.shgang.cn/assets/images/avatar.png" alt="头像">
            </div>
          </div>
          <div class="form-box">
            <div class="input-box">
              <el-input
                  v-model="comment"
                  :autosize="{ minRows: 2, maxRows: 20 }"
                  type="textarea"
                  placeholder="请输入评论"
                  class="rich-input empty"
                  contenteditable="true"
                  spellcheck="false"
              />
            </div>
            <div class="action-box">
              <div class="emoji-box">
                表情
              </div>
              <div class="img-btn">
                图片
              </div>
              <div class="submit-box">
                <span>⌘ + Enter</span>
                <button class="submit-btn" type="submit">发表评论</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="comment-list-wrapper" v-show="true">
        <div><span>helllo</span></div>
        <div><span>helllo</span></div>
        <div><span>helllo</span></div>
        <div><span>helllo</span></div>
        <div><span>helllo</span></div>
        <div><span>helllo</span></div>
        <div><span>helllo</span></div>
        <div><span>helllo</span></div>
        <div><span>helllo</span></div>
        <div><span>helllo</span></div>
        <div><span>helllo</span></div>
        <div><span>helllo</span></div>
        <div><span>helllo</span></div>
      </div>
      <div></div>

    </div>
    <div class="bottom-area-container">
     <div></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      article: {
        title: '观察者模式',
        date: '文章标题',
        viewCount: '文章标题',
        content: '**摘要**：AOV简介，拓扑排序的思路及实现\n' +
            '\n' +
            '<!-- more -->\n' +
            '\n' +
            '### AOV网（Activity On Vertex Network）\n' +
            '\n' +
            '在一个表示工程的有向图中，用顶点表示活动（Activity），用有向边表示活动之间的先后顺序，AOV网中不能出现环。\n' +
            '\n' +
            '### 拓扑排序\n' +
            '\n' +
            '拓扑排序是对**有向无环图**的顶点的一种排序，使得如果存在一条从vi到vj的路径，那么排序中vj就出现在vi的后面\n' +
            '\n' +
            '#### 思路：\n' +
            '\n' +
            '- 从任意一个入度为0的顶点开始，将这个顶点及从这个顶点出发的边删除\n' +
            '- 重复上述操作，直到找不到入度为0的顶点\n' +
            '\n' +
            '~~~java\n' +
            'public List<K> topologicalSort() {\n' +
            '    List<K> list = new ArrayList<>();\n' +
            '\n' +
            '    Queue<Vertex<K, E>> zeroInDegreeQueue = new LinkedList<>();\n' +
            '    Map<Vertex<K, E>, Integer> inMap = new HashMap<>();\n' +
            '    vertices.forEach((k, v) -> {\n' +
            '        if (v.inEdges.size() == 0) {\n' +
            '            zeroInDegreeQueue.offer(v);\n' +
            '        } else {\n' +
            '            inMap.put(v, v.inEdges.size());\n' +
            '        }\n' +
            '    });\n' +
            '\n' +
            '    while (!zeroInDegreeQueue.isEmpty()) {\n' +
            '        Vertex<K, E> vertex = zeroInDegreeQueue.poll();\n' +
            '        list.add(vertex.key);\n' +
            '        vertex.outEdges.forEach(edge -> {\n' +
            '            inMap.put(edge.to, inMap.get(edge.to) - 1);\n' +
            '            if (inMap.get(edge.to) == 0) {\n' +
            '                zeroInDegreeQueue.offer(edge.to);\n' +
            '            }\n' +
            '        });\n' +
            '    }\n' +
            '    return list;\n' +
            '}\n' +
            '~~~\n' +
            '~~~go\n' +
            'func main() {\n' +
            '\n' +
            '}\n' +
            '~~~\n' +
            ':::tip\n' +
            '\n',

      },
      comment: ''
    }
  },
  methods: {
    async getArticleById() {
      const {data: res} = await this.$http.article.getArticleById(this.$route.params.id)
      console.log(res);
    }
  },
  created() {
    this.getArticleById()
  }
};
</script>

<style lang="scss" scoped>
.title-wrapper {
  text-align: center;
  .article-info {
    span {
      margin: 0 10px;
    }
  }
}
.line-before-content {
  display: flex;
  justify-content: center;
  align-items: center;
  >div {
    margin-top: 20px;
    width: 90%;
    height: 1px;
    background-color: #dfdfdf;
  }
}
.line-after-content {
   div {
     height: 20px;
     background-color: rgb(239, 239, 239);
   }
}
.comment-container {
}
.bottom-area-container {
  div {
    height: 100px;
    background-color: rgb(239, 239, 239);
  }
}

.comment-container {
  .header {
    height: 30px;
    margin-bottom: 20px;
  }
  margin-top: 20px;
  padding: 0 5%;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 100px;
    margin-bottom: 10px;
    .avatar-box {
      border-radius: 50%;
      width: 80px;
      img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
      }
    }
    .form-box {
      width: calc(100% - 80px);
      padding: 8px 12px;
      flex: 1 1 auto;
      position: relative;
      min-height: 100px;
      .comment-form {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
      }
      .action-box {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        .submit-btn {
          margin-left: auto;
          width: 92px;
          text-align: center;
          font-size: 14px;
          line-height: 36px;
          background: #abcdff;
          //background: #1e80ff; // 有文字可以发布用深色
          border-radius: 4px;
          color: #fff;
          padding: 0;
          cursor: pointer;
          border: none;
        }
      }
    }
  }

  .comment-list-wrapper {
    padding-top: 30px;
  }
}
</style>
