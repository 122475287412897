import axios from 'axios'
import jsonbig from 'json-bigint'

const request = axios.create({
    baseURL: 'http://shgang.cn:8080/xblog/api/v1',
    transformResponse: data => {
        const json = jsonbig({
            storeAsString: true
        })
        return json.parse(data)
    }
})

export default request
