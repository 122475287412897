<template>
  <div class="header" :style="opacityStyle" v-show="showHeader">
    <div class="xb-nav">
      <div class="xb-container">
        <div class="logo">
          <div class="logo-name-cn"><strong>Shgang's Blog</strong></div>
          <div class="logo-name-en">X B L O G</div>
        </div>
        <div class="left">
          <span><router-link to="/"><i class="iconfont icon-shouye"></i>首页</router-link> </span>
          <span><router-link to="archive"><i class="iconfont icon-guidang"></i>归档</router-link> </span>
          <span><router-link to="category"><i class="iconfont icon-fenlei"></i>分类</router-link> </span>
          <span><router-link to="tag"><i class="iconfont icon-24gl-tags2"></i>标签</router-link> </span>
          <span><router-link to="about"><i class="iconfont icon-guanyuwomen"></i>关于</router-link> </span>
          <span><router-link to="link"><i class="iconfont icon-lianjie"></i>友链</router-link> </span>
        </div>
        <div class="right">
          <div class="search-container">
            <form class="search-form" @submit.prevent>
              <input v-model="searchContent" @keyup.prevent="search" type="search" maxlength="64" placeholder="探索心月狐的流火号" class="search-input"/>
              <div class="search-icon-container" @click="search"><i class="iconfont icon-sousuo"></i> </div>
            </form>
          </div>
          <div class="has-login-wrapper" v-if="hasLogin">

            <div class="writing-btn-container">
              <nav class="write-article">
                <router-link to="/editor" class="write-article-link">
                  <el-button class="writing-btn" type="primary"><i class="iconfont icon-Writing"></i>发布文章</el-button>
                </router-link>
              </nav>
            </div>
            <div class="avatar-container">
              <el-dropdown>
              <span class="el-dropdown-link">
                <img :src="this.userInfo.avatar" alt="头像">
              </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item>
                      <div @click="logout">个人中心</div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <div @click="logout">退出</div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
          <div class="login-button-wrapper" v-else>
            <div class="login-btn-container">
              <router-link to="/login" class="write-article-link">
                <el-button type="primary" plain>登录</el-button>
              </router-link>
            </div>
            <div class="register-btn-container">
              <router-link to="/login" class="write-article-link">
                <el-button type="primary" plain>注册</el-button>
              </router-link>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {
        username: '',
        avatar: ''
      },
      hasLogin: false,
      searchContent: '',
      opacityStyle: {
        opacity: 1
      },
      showHeader: true
    }
  },
  methods: {
    logout() {
      this.hasLogin = false
      this.$store.commit('REMOVE_INFO')
      this.$router.push('/')
    },
    search() {
      console.log(this.searchContent);
    },
    handleScroll(e) {
      let top = document.documentElement.scrollTop
      if (top < 45) {
        this.opacityStyle = {opacity: 1}
        this.showHeader = true
      } else if (45 <= top && top < 200) {
        let opacity = 1 - (top / 200)
        this.opacityStyle = {opacity: opacity}
        this.showHeader = true
      } else {
        this.showHeader = false
      }
    }
  },
  created() {
    if (this.$store.getters.isLogin) {
      this.hasLogin = true
      this.userInfo = this.$store.getters.getUser
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
  }
};
</script>

<style lang="scss" scoped>
.xb-container {
  position: fixed;
  top: 0;
  background-color: white;
  height: 60px;
  box-shadow: 1px 1px 5px #0003;
  width: 100%;
  /*width: 1200px;*/
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px ;
}
.logo {
  width: 12%;
  font-size: 18px;
  transform: scale(0.7);
  text-align: center;
  /*color: #409eff;*/
  .logo-name-cn {
    font-size: 24px;
  }
  .logo-name-en {
    font-size: 12px;
  }
}

a {
  color: #2b3539;
  text-decoration: none;
  &:hover {
    color: cornflowerblue;
  }
}
.left {
  width: 33%;
  display: flex;
}
.left span {
  margin-left: 30px;
}
.right {
  width: 55%;
  /*margin-right: 2em;*/
  /*text-align: right;*/
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-container {
  margin-left: 50px;
  /*width: 100%;*/
}
.search-form {
  width: 360px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  justify-content: space-between;
  border-radius: 4px;
  position: relative;
  height: 2.2rem;
  border: 1px solid #c2c8c1;
  transition: width .2s;
}
.search-form:hover {
  border-color: #7d7f7c;
}
.search-input {
  height: 30px;
  width: calc(100% - 44px);
  border: none;
  outline: none;
}
.search-icon-container {
  position: relative;
  left: -2px;
  width: 44px;
  height: 30px;
  background: #f2f3f5;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-icon-container:hover {
  cursor: pointer;
}
.has-login-wrapper, .login-button-wrapper {
  display: flex;
  margin-right: 20px;
  justify-content: space-between;
  align-items: center;
}
.avatar-container, .writing-btn-container {
  margin: 0 15px;
}
.login-btn-container, .register-btn-container {
  margin: 0 5px;
}
img {
  height: 40px;
  border-radius: 50%;
}
</style>
