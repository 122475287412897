import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import createTipPlugin from '@kangc/v-md-editor/lib/plugins/tip/index.js';
import '@kangc/v-md-editor/lib/plugins/tip/tip.css';
import createHighlightLinesPlugin from '@kangc/v-md-editor/lib/plugins/highlight-lines/index';
import '@kangc/v-md-editor/lib/plugins/highlight-lines/highlight-lines.css';
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';
// 引入所有语言包
import hljs from 'highlight.js';

VueMarkdownEditor
  .use(githubTheme, {Hljs: hljs})
  .use(createTipPlugin())
  .use(createHighlightLinesPlugin())
  .use(createCopyCodePlugin());

export {VueMarkdownEditor}
