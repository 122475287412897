<template>
  <div class="editor-container">
    <div class="editor-header">
      <div class="left-box">

      </div>
      <input class="title-input" placeholder="输入文章标题。。。" spellcheck="false" maxlength="80">
      <div class="right-box">

      </div>
    </div>

    <div class="editor-main">
      <v-md-editor height="calc(100% - 100px)"></v-md-editor>
      <div class="test">
        <span>hello</span>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  components: {
  }
};
</script>

<style lang="scss" scoped>
.editor-container {
  position: fixed;
  width: 100%;
  height: 100%;
}
.editor-header {
  padding: 0 27px;
  background-color: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #d1d1d1;
  .title-input {
    flex: 1 1 auto;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 500;
    color: #1d2129;
    border: none;
    outline: none;
  }
}
.editor-main {
  //top: 60px;
  //position: fixed;
  width: 100%;
  height: 100%;
  .md-stats {
    border-top: 1px solid #e1e4e8;
    //border-top: 1px solid red;
    span {
      padding-left: 16px;
    }
  }
  .test {
    height: 100px;
  }
}
</style>
