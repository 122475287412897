<template>
  主页
</template>

<script>
export default {
  name: 'Home'
};
</script>

<style scoped>

</style>