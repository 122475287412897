<template>
  <div>link</div>
</template>

<script>
export default {
  name: "Link"
};
</script>

<style scoped>

</style>
