<template>
  <div class="detail-container">
    <Header></Header>
    <Main></Main>
  </div>
</template>

<script>
import Header from "@/components/web/Header.vue";
import Main from "@/components/web/Main.vue";

export default {
  components: {
    Header,
    Main,
  }
};
</script>

<style scoped>

</style>
